import { dateMMDDYYY, numberWithCommas } from '@/lib/utils';
import MinimizedSymbolSummary from '@/components/MinimizedSymbolSummary';
import { Box, IconButton, TextField } from '@mui/material';
import { ArrowDownward, ArrowUpward, Close } from '@mui/icons-material';
import { ACTIONS, CELL_TYPES } from '@/lib/utils/constants';
import Price from '@/components/Price';

export const stockFieldsConfig = {
  gapUp: {
    label: 'Gap up (%)',
    name: 'gapUp',
    disablePadding: false,
    numeric: true,
  },
  morningGapUp: {
    label: 'M. Gap up (%)',
    name: 'morningGapUp',
    disablePadding: false,
    numeric: true,
  },
  Volume: {
    label: 'Volume',
    name: 'Volume',
    numeric: true,
    disablePadding: false,
    formatter: (val, key, data) => {
      return val ? numberWithCommas(val) : val;
    },
  },
  Percentage: {
    label: 'Percentage',
    name: 'Percentage',
    numeric: true,
    disablePadding: false,
  },
  TimeStamp: {
    label: 'Date',
    name: 'TimeStamp',
    disablePadding: false,
    formatter: dateMMDDYYY,
    align: 'left',
  },
  HighPrice: {
    label: 'High',
    numeric: true,
    name: 'HighPrice',
    disablePadding: false,
  },
  OpenPrice: {
    label: 'Open',
    numeric: true,
    name: 'OpenPrice',
    disablePadding: false,
  },
  LowPrice: {
    label: 'Low',
    numeric: true,
    disablePadding: false,
    name: 'LowPrice',
  },
  ClosePrice: {
    label: 'Close',
    numeric: true,
    name: 'ClosePrice',
    disablePadding: false,
  },
  VWAP: {
    label: 'VWAP',
    numeric: true,
    name: 'VWAP',
    disablePadding: false,
  },
  PreviousClose: {
    label: 'P. Close',
    numeric: true,
    name: 'PreviousClose',
    disablePadding: false,
  },
  CurrentPrice: {
    label: 'Price',
    numeric: true,
    name: 'CurrentPrice',
    disablePadding: false,
    formatter: (val) => {
      const _val = val ? parseFloat(val).toFixed(2) : '-';
      return <Price val={_val} />;
    },
    type: 'React',
  },
  Symbol: {
    label: 'Symbol',
    numeric: false,
    name: 'Symbol',
    disablePadding: false,
    align: 'left',
  },
  issueSymbol: {
    label: 'Symbol',
    numeric: false,
    name: 'Symbol',
    disablePadding: false,
    align: 'left',
  },
  haltDate: {
    label: 'H. Date',
    numeric: false,
    name: 'TimeStamp',
    disablePadding: false,
    align: 'left',
    formatter: (val) => dateMMDDYYY(val),
  },
  haltTime: {
    label: 'H. Time',
    numeric: false,
    name: 'HaltTime',
    disablePadding: false,
    align: 'left',
  },
  resumptionTime: {
    label: 'R. Time',
    numeric: false,
    name: 'ResumptionTime',
    disablePadding: false,
    align: 'left',
  },
  reasonCode: {
    label: 'Reason',
    numeric: false,
    name: 'reasonCode',
    disablePadding: false,
    align: 'left',
  },
  enrichedSymbolWithMovement: {
    label: 'Symbol',
    name: 'enrichedSymbolWithMovement',
    disablePadding: false,
    formatter: (val, key, data) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {data.movedUp ? (
            <ArrowUpward
              sx={{ color: 'var(--price-move-up-text)', fontSize: '1rem' }}
            />
          ) : data.movedDown ? (
            <ArrowDownward
              sx={{ color: 'var(--price-move-down-text)', fontSize: '1rem' }}
            />
          ) : (
            <></>
          )}
          <Box>{data.Symbol}</Box>
        </Box>
      );
    },
    align: 'left',
    type: 'React',
  },
  enrichedEditableSymbolWithMovement: {
    label: 'Symbol',
    name: 'enrichedEditableSymbolWithMovement',
    disablePadding: false,
    formatter: (val, key, data, dataIndex) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {data.movedUp ? (
            <ArrowUpward sx={{ color: 'green', fontSize: '1rem' }} />
          ) : data.movedDown ? (
            <ArrowDownward sx={{ color: 'red', fontSize: '1rem' }} />
          ) : (
            <></>
          )}
          <Box sx={{ width: 100 }}>
            {data.inEditMode ? (
              <TextField
                placeholder="Symbol"
                size="small"
                onChange={(event) =>
                  data.onChange(dataIndex, event.target.value)
                }
                onKeyDown={(event) =>
                  data.onSubmit(dataIndex, event.target.value)
                }
                value={data.Symbol}
                sx={{ width: 100 }}
                onBlur={() => data.onSubmit(dataIndex)}
              />
            ) : (
              <Box onClick={() => data.onClick(dataIndex)}>{data.Symbol}</Box>
            )}
          </Box>
        </Box>
      );
    },
    align: 'left',
    type: 'React',
  },
  mimizedStockSymbolSummary: {
    label: 'Symbol',
    name: 'mimizedStockSymbolSummary',
    disablePadding: false,
    formatter: (val, key, data) => {
      return (
        <MinimizedSymbolSummary
          symbol={data.Symbol}
          timestamp={data.TimeStamp}
          gapUp={data.gapUp}
        />
      );
    },
    align: 'left',
    type: 'React',
  },
  minimizedStockPriceSummary: {
    label: 'Open | High | Low | Close',
    name: 'minimizedStockPriceSummary',
    disablePadding: false,
    formatter: (val, key, data) => {
      return `${data.OpenPrice} | ${data.HighPrice} | ${data.LowPrice} | ${data.ClosePrice}`;
    },
    align: 'left',
  },
  actions: {
    delete: {
      disablePadding: true,
      formatter: (val, key, data) => {
        return (
          <IconButton size="small">
            <Close />
          </IconButton>
        );
      },
      align: 'left',
      type: CELL_TYPES.ACTION,
      action: ACTIONS.DELETE,
    },
  },
  momentumStockSummary: {
    label: 'Summary',
    name: 'momentumSummary',
    formatter: (val, key, data) => {
      return `${data.Symbol} moved ${
        data.ClosePrice > data.OpenPrice ? 'up' : 'down'
      } by ${data.percentage}%`;
    },
    align: 'left',
  },
};

export const stockFieldsOrder = [
  'Symbol',
  'TimeStamp',
  'gapUp',
  'morningGapUp',
  'OpenPrice',
  'HighPrice',
  'LowPrice',
  'ClosePrice',
  'Volume',
  'PreviousClose',
];

export const haltedStockFieldsOrder = ['issueSymbol', 'haltDate', 'reasonCode'];

export const stockFieldsMinimizedOrder = [
  'mimizedStockSymbolSummary',
  'minimizedStockPriceSummary',
];
